import * as bootstrap from 'bootstrap';

document.addEventListener('turbo:load', function() {
  // Set focus to site-wide search when forward slash key is pressed
  document.addEventListener('keyup', function(event) {
    // Ignore when any modifier keys are pressed (CTRL, Alt, Meta)
    if (event.key !== '/' || event.ctrlKey || event.metaKey) {
      return;
    }
    if (/^(?:input|textarea|select|button)$/i.test(event.target.tagName)) {
      return;
    }
    event.preventDefault();
    return document.getElementById('site_search_query').focus();
  });
  // Open or close the Offcanvas Site Sidebar menu when the "m" key is pressed
  return document.addEventListener('keyup', function(event) {
    var siteSidebarOffcanvas;
    if (document.querySelector('body.modal-open')) {
      return;
    }
    if (document.querySelector('.js-menu-shortcut')) {
      // Ignore when any modifier keys are pressed (CTRL, Alt, Meta)
      if (event.key !== 'm' || event.ctrlKey || event.metaKey) {
        return;
      }
      if (/^(?:input|textarea|select|button)$/i.test(event.target.tagName)) {
        return;
      }
      event.preventDefault();
      siteSidebarOffcanvas = bootstrap.Offcanvas.getOrCreateInstance('.js-menu-shortcut');
      if (siteSidebarOffcanvas._element.classList.contains('show')) {
        return siteSidebarOffcanvas.hide();
      } else {
        return siteSidebarOffcanvas.show();
      }
    }
  });
});
