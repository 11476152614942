import {
  Controller
} from '@hotwired/stimulus';

export default (function() {
  var _Class;

  // Connects to data-controller="progress-bar"
  _Class = class extends Controller {
    connect() {
      this.observer = new MutationObserver((mutationsList, _observer) => {
        var i, len, mutation, results;
        results = [];
        for (i = 0, len = mutationsList.length; i < len; i++) {
          mutation = mutationsList[i];
          if (mutation.type === 'childList') {
            results.push(this.increment());
          } else {
            results.push(void 0);
          }
        }
        return results;
      });
      return this.observer.observe(this.element, {
        childList: true
      });
    }

    increment() {
      this.completedItemsValue++;
      return this.updateProgress();
    }

    updateProgress() {
      var percentComplete;
      percentComplete = Math.round((this.completedItemsValue / this.totalItemsValue) * 100);
      if (isFinite(percentComplete)) {
        this.progressWrapperTarget.setAttribute('aria-valuenow', percentComplete);
        this.progressBarTarget.style.width = `${percentComplete}%`;
        return this.progressBarTarget.innerText = `${percentComplete}%`;
      } else {
        return this.progressWrapperTarget.style.display = 'none';
      }
    }

  };

  _Class.values = {
    totalItems: {
      type: Number,
      default: 0
    },
    completedItems: {
      type: Number,
      default: 0
    }
  };

  _Class.targets = ['progressWrapper', 'progressBar'];

  return _Class;

}).call(this);
