(function() {
  var initializeScrolling;

  initializeScrolling = function() {
    var stickyHeadingScrollHandler;
    // Reset the sticky headings/breadcrumbs when the page "loads" via Turbo or
    // when an actual page load occurs
    $('body').removeClass('sticky-breadcrumbs');
    $(window).off('scroll', stickyHeadingScrollHandler);
    // ~~~ Sticky Headings & Breadcrumbs ~~~
    stickyHeadingScrollHandler = function(stickyContainerElement, activationClass, controlElement) {
      var initialHeadingOffset, pageHeaderHeight;
      if (controlElement == null) {
        controlElement = stickyContainerElement; // default, if not provided
      }
      initialHeadingOffset = $(stickyContainerElement).offset().top;
      pageHeaderHeight = $('nav.navbar').outerHeight();
      return $(window).scroll(function() {
        var isScrolledPastStickyHeading, stickyHeadingCurrentPosition;
        // measure position from bottom of fixed header
        stickyHeadingCurrentPosition = initialHeadingOffset - pageHeaderHeight;
        isScrolledPastStickyHeading = $(this).scrollTop() > stickyHeadingCurrentPosition;
        return $(controlElement).toggleClass(activationClass, isScrolledPastStickyHeading);
      });
    };
    // Set up sticky breadcrumbs and sticky table headings
    if ($('.sticky-heading').length > 0) {
      stickyHeadingScrollHandler('.sticky-heading', 'stuck-heading');
    }
    if ($('.breadcrumb-container').length > 0) {
      return stickyHeadingScrollHandler('.breadcrumb-container', 'sticky-breadcrumbs', 'body');
    }
  };

  document.addEventListener('turbo:load', function() {
    return initializeScrolling();
  });

  document.addEventListener('turbo:render', function() {
    if (!event.detail.isPreview) {
      return initializeScrolling();
    }
  });

}).call(this);
