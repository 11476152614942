import {
  Controller
} from '@hotwired/stimulus';

export default (function() {
  var _Class;

  // Connects to data-controller="timeline-chart"
  _Class = class extends Controller {
    connect() {
      var parsedChartOptions;
      parsedChartOptions = JSON.parse(this.optionsValue);
      return Highcharts.chart(this.element.id, parsedChartOptions);
    }

  };

  _Class.values = {
    options: String
  };

  return _Class;

}).call(this);
