import {
  Controller
} from '@hotwired/stimulus';

import {
  errorWithMessage
} from '@/custom/appsignal';

import * as bootstrap from 'bootstrap';

export default (function() {
  var _Class, _copyElementToClipboard, _failureCallback, _filteredTableForCopy, _provideCopyToClipboardFeedback, _removeAnchorTags, _setTableStyles;

  // Connects to data-controller="clipboard"
  _Class = class extends Controller {
    copy() {
      var copiedText, tooltip;
      copiedText = this.sourceTarget.getAttribute('value');
      tooltip = bootstrap.Tooltip.getOrCreateInstance(this.buttonTarget);
      return navigator.clipboard.writeText(copiedText).then(_provideCopyToClipboardFeedback(this.element, tooltip), _failureCallback);
    }

    copyTable() {
      var copiedTable, tooltip;
      tooltip = bootstrap.Tooltip.getOrCreateInstance(this.buttonTarget);
      copiedTable = _filteredTableForCopy(this.sourceTarget);
      return _copyElementToClipboard(copiedTable).then((success) => {
        return _provideCopyToClipboardFeedback(this.buttonTarget, tooltip);
      }, function(error) {
        return _failureCallback(error);
      });
    }

  };

  _Class.targets = ['button', 'source'];

  // Private
  _provideCopyToClipboardFeedback = function(element, tooltip) {
    var copyIcon, successIcon;
    copyIcon = element.querySelector('i:first-of-type');
    successIcon = element.querySelector('i:last-of-type');
    copyIcon.classList.add('d-none');
    successIcon.classList.remove('d-none');
    tooltip.show();
    return window.setTimeout((function() {
      tooltip.hide();
      copyIcon.classList.remove('d-none');
      return successIcon.classList.add('d-none');
    }), 2000); // 2 seconds
  };

  _copyElementToClipboard = function(element) {
    var newClipboardItem;
    newClipboardItem = new ClipboardItem({
      'text/html': new Blob([element.outerHTML], {
        type: 'text/html'
      }),
      'text/plain': new Blob([element.innerText], {
        type: 'text/plain'
      })
    });
    return navigator.clipboard.write([newClipboardItem]);
  };

  _removeAnchorTags = function(table) {
    var element, i, len, ref, results1;
    ref = table.querySelectorAll('a');
    results1 = [];
    for (i = 0, len = ref.length; i < len; i++) {
      element = ref[i];
      results1.push(element.replaceWith(...element.childNodes));
    }
    return results1;
  };

  _setTableStyles = function(table) {
    var cell, i, j, len, len1, ref, ref1, results1;
    table.setAttribute('style', 'border: 1px solid black; border-collapse: collapse;');
    table.removeAttribute('class');
    ref = table.querySelectorAll('th, td');
    for (i = 0, len = ref.length; i < len; i++) {
      cell = ref[i];
      cell.removeAttribute('class');
      cell.style.border = '1px solid black';
      cell.style.padding = '8px';
    }
    ref1 = table.querySelectorAll('td:nth-last-child(-n+2)');
    results1 = [];
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      cell = ref1[j];
      // Only apply to the last two columns (Premium Amount / Fixed Rate, DF Mid):
      results1.push(cell.style.textAlign = 'right');
    }
    return results1;
  };

  _filteredTableForCopy = function(table) {
    var cells, columnIndex, columnsToDelete, filteredTable, i, j, len, len1, ref, row, rowIndex, tableHeadings;
    filteredTable = table.cloneNode(true);
    tableHeadings = Array.from(filteredTable.querySelectorAll('th'));
    columnsToDelete = tableHeadings.reduce(function(results, th, index) {
      if (th.dataset.clipboard !== 'copy') {
        th.remove();
        results.unshift(index);
      }
      return results;
    }, []);
    ref = filteredTable.querySelectorAll('tbody tr');
    for (rowIndex = i = 0, len = ref.length; i < len; rowIndex = ++i) {
      row = ref[rowIndex];
      cells = row.children;
      for (j = 0, len1 = columnsToDelete.length; j < len1; j++) {
        columnIndex = columnsToDelete[j];
        cells[columnIndex].remove();
      }
    }
    _removeAnchorTags(filteredTable);
    _setTableStyles(filteredTable);
    return filteredTable;
  };

  _failureCallback = function(error) {
    return errorWithMessage(error, 'Could not copy text to clipboard');
  };

  return _Class;

}).call(this);
