import {
  Controller
} from '@hotwired/stimulus';

import * as bootstrap from 'bootstrap';

// Connects to data-controller="portfolio"
export default (class extends Controller {
  connect() {
    var triggerElement;
    // Expand the portfolio project section when the page loads
    if (document.location.hash.length) {
      triggerElement = document.querySelector(document.location.hash);
      if (triggerElement) {
        return bootstrap.Collapse.getOrCreateInstance(triggerElement).show();
      }
    }
  }

});
