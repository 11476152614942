import Bloodhound from 'corejs-typeahead/dist/bloodhound';

import 'corejs-typeahead/dist/typeahead.jquery';

document.addEventListener('turbo:load', function() {
  var rateFileEngineConstructor;
  // On Derivative page
  $('#rate_file').on('typeahead:select', function(event, suggestion) {
    $('#rate_file_id').val(suggestion.id);
    $('#valuation_rate_file_id').val(suggestion.id);
    return $('#pricing_rate_file_id').val(suggestion.id);
  });
  // On Pricing form
  $('#pricing_rate_file').on('typeahead:select', function(event, suggestion) {
    return $('#pricing_rate_file_id').val(suggestion.id);
  });
  rateFileEngineConstructor = function(sourceUrl) {
    var engine;
    engine = new Bloodhound({
      datumTokenizer: function(datum) {
        return Bloodhound.tokenizers.whitespace(datum);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: sourceUrl,
        prepare: function(query, settings) {
          settings.data = {
            filename: query
          };
          return settings;
        }
      }
    });
    return engine.ttAdapter();
  };
  return $('.js-rate-file-typeahead').each(function() {
    var sourceUrl;
    sourceUrl = $(this).data('source');
    return $(this).typeahead({
      classNames: {
        wrapper: 'w-100'
      },
      minLength: 0,
      highlight: true
    }, {
      source: rateFileEngineConstructor(sourceUrl),
      displayKey: 'filename',
      limit: 40, // FIXME: see issue #652
      templates: {
        suggestion: function(suggestion) {
          return `<div><span>${suggestion.filename}</span></div>`;
        }
      }
    });
  });
});
