import {
  Controller
} from '@hotwired/stimulus';

export default (function() {
  var _Class;

  // Connects to data-controller="analytics"
  _Class = class extends Controller {
    connect() {
      if (window.analytics) {
        return analytics.trackLink(this.element, this.trackableEventValue, JSON.parse(this.payloadValue));
      }
    }

  };

  _Class.values = {
    trackableEvent: String,
    payload: String
  };

  return _Class;

}).call(this);
