var initializeLoanTranches;

import AliceUtilities from '@/custom/utilities';

initializeLoanTranches = function() {
  var updateLoanTrancheSpread;
  $('#loan_tranche_period_choice_term_id').on('change', function() {
    var baseUrl, form, fullUrl, periodChoiceTermId;
    form = this.form;
    periodChoiceTermId = $('#loan_tranche_period_choice_term_id').val();
    baseUrl = $(this).data('baseUrl');
    fullUrl = `${baseUrl}${periodChoiceTermId}`;
    if (periodChoiceTermId !== '') {
      return $.ajax({
        url: fullUrl,
        dataType: 'json',
        success: function(data, status, xhr) {
          return updateLoanTrancheSpread(form, data.spread_percentage);
        },
        error: function(data) {
          return AliceUtilities.displayAjaxStatusIndicator(form, '.js-period-choice-term .error');
        }
      });
    }
  });
  return updateLoanTrancheSpread = function(form, spreadPercentage) {
    var spreadField;
    spreadField = $(form).find('#loan_tranche_spread');
    if (spreadField.val() === '' && (spreadPercentage != null)) {
      spreadField.val(spreadPercentage);
      return AliceUtilities.displayAjaxStatusIndicator(form, '.js-spread .success');
    }
  };
};

document.addEventListener('turbo:load', function() {
  return initializeLoanTranches();
});

document.addEventListener('turbo:render', function() {
  if (!event.detail.isPreview) {
    return initializeLoanTranches();
  }
});
