import * as bootstrap from 'bootstrap';

import Cocooned from '@notus.sh/cocooned';

import LocalTime from 'local-time';

import AliceUtilities from '@/custom/utilities';

document.addEventListener('turbo:load', function() {
  var cloneInputElement, setDefaultTriggerValues, triggerElement;
  // Initialize LocalTime
  LocalTime.start();
  // Set the active tab on arrival if the tab is linked to from another page
  if ($('.nav-tabs').length > 0 && document.location.hash) {
    triggerElement = document.querySelector(`.nav-tabs a[href='${document.location.hash}']`);
    if (triggerElement) {
      bootstrap.Tab.getOrCreateInstance(triggerElement).show();
    }
  }
  // Update the url when the tab changes
  $('.nav-tabs a').on('shown.bs.tab', function(event) {
    // TODO: use "hash" or "href"?
    Turbo.navigator.history.push(event.target.href);
    return history.pushState(history.state, null, event.target.href);
  });
  // Add additional form fields to the Pricing form
  cloneInputElement = function(container, newValue = '') {
    var clonedElement, clonedElementId, elementToClone, inputCount;
    elementToClone = container.find('input.form-control').last();
    clonedElement = elementToClone.clone();
    clonedElement.val(newValue);
    inputCount = container.find('input').length;
    clonedElementId = elementToClone.attr('id').replace(/\d/, inputCount);
    clonedElement.attr('id', clonedElementId);
    elementToClone.after(clonedElement);
    return false;
  };
  $('.js-add-termination-date-field').click(function(event) {
    return cloneInputElement($('.termination-dates-container'));
  });
  $('.js-add-strike-rate-field').click(function(event) {
    return cloneInputElement($('.strike-rates-container'));
  });
  // Clear the Termination Dates and Strike Rates on the Pricing form
  $('.js-reset-pricing-form').click(function(event) {
    $('.termination-dates-container').find('input.form-control').val(null);
    return $('.strike-rates-container').find('input.form-control').val(null);
  });
  // Quick populate the Strike Rates on the Pricing form
  $('.js-quick-populate-pricing input[type=submit]').click(function(event) {
    var count, form, i, index, inputField, j, lastStrikeRate, len, newValue, ref, ref1, results, step, strikeRates;
    event.preventDefault();
    form = $(event.target.closest('form'));
    step = parseFloat(form.find('#quick-populate-step').val());
    count = parseFloat(form.find('#quick-populate-count').val());
    strikeRates = $('.strike-rates-container input.form-control').map(function() {
      return parseFloat($(this).val());
    });
    lastStrikeRate = strikeRates.get().filter(function(x) {
      return x;
    }).pop();
    if (step && count && lastStrikeRate) {
      for (index = i = 0, ref = count - 1; (0 <= ref ? i <= ref : i >= ref); index = 0 <= ref ? ++i : --i) {
        newValue = lastStrikeRate + (step * (index + 1));
        // N.B. keep 5 decimal places
        cloneInputElement($('.strike-rates-container'), newValue.toFixed(5));
      }
      ref1 = $('.strike-rates-container input.form-control');
      results = [];
      for (j = 0, len = ref1.length; j < len; j++) {
        inputField = ref1[j];
        if (inputField.value === '') {
          results.push(inputField.remove());
        } else {
          results.push(void 0);
        }
      }
      return results;
    }
  });
  // Display loading indicator when appropriate
  $('.js-show-loading-indicator').click(function() {
    return AliceUtilities.showLoadingIndicator();
  });
  // Display loading indicator when paginating through the Valuations Report results
  $('.js-valuation-pagination a').click(function() {
    return AliceUtilities.showLoadingIndicator();
  });
  setDefaultTriggerValues = function(form) {
    var iriField, termField;
    termField = form.find('#loan_trigger_term_id');
    if (termField.val() === '') {
      termField.val(termField.data('default')).trigger('change');
    }
    iriField = form.find('#loan_trigger_interest_rate_index_id');
    if (iriField.val() === '') {
      return iriField.val(iriField.data('default')).trigger('change');
    }
  };
  // Set default trigger field values if fields are empty and Trigger Required is checked
  $('.loan_trigger_required').on('change', function(event) {
    var loanForm;
    loanForm = $(event.target).parents('form');
    if ($(event.target).is(':checked')) {
      return setDefaultTriggerValues(loanForm);
    }
  });
  // Animate the "Back to top" motion and prevent the page from reloading
  $('.js-back-to-top').click(function(event) {
    var duration;
    event.preventDefault();
    duration = 300; // seconds
    $('html, body').animate({
      scrollTop: 0
    }, duration);
    return false;
  });
  // Show the current text field length
  $('.js-text-length-counter').on('keyup', function(event) {
    var currentLength, hintElement, input, message;
    input = $(event.target);
    currentLength = input.val().length;
    hintElement = input.siblings('.form-text');
    message = currentLength === 1 ? 'character' : 'characters';
    return hintElement.text(`Current length: ${currentLength} ${message}`);
  });
  $('.js-scroll-into-view').click(function(event) {
    var location;
    location = event.currentTarget.dataset.target;
    this.blur();
    return AliceUtilities.scrollSectionIntoView(location);
  });
  // Scroll the component sidebar item into view if active, when the offcanvas opens
  return $('#offcanvas-directory-list').on('show.bs.offcanvas', function(event) {
    if (document.querySelector('.js-component-sidebar-item.active')) {
      return document.querySelector('.js-component-sidebar-item.active').scrollIntoView({
        inline: 'start'
      });
    }
  });
});
