import '@/custom/appsignal';

import '@hotwired/turbo-rails';

import 'trix';

import '@rails/actiontext';

import jQuery from 'jquery';

window.jQuery = jQuery;

window.$ = jQuery;

import '@/controllers/index';

import '@/channels/index';

import '@/stream_actions/index';

import '@/custom/charts';

import '@/custom/main';

import '@/custom/filtering';

import '@/custom/scrolling';

import '@/custom/addresses';

import '@/custom/notionals';

import '@/custom/loan_and_asset_balances';

import '@/custom/rate_files';

import '@/custom/loan_tranches';

import '@/custom/shortcuts';
