var initializeLoanAndAssetBalances;

import AliceUtilities from '@/custom/utilities';

import * as bootstrap from 'bootstrap';

initializeLoanAndAssetBalances = function() {
  // Set up paste loan balances form
  $('#paste-loan-balances-modal').on('shown.bs.modal', function() {
    var destination, inputFields;
    inputFields = $('.js-loan-balance-amount-input');
    destination = $(this).find('#pasted_loan_balance_amounts');
    AliceUtilities.populateWithIndividualValues(inputFields, destination);
    inputFields = $('.js-loan-balance-spread-rate-input');
    destination = $(this).find('#pasted_loan_balance_spread_rates');
    AliceUtilities.populateWithIndividualValues(inputFields, destination);
    inputFields = $('.js-loan-balance-budgeted-rate-input');
    destination = $(this).find('#pasted_loan_balance_budgeted_rates');
    return AliceUtilities.populateWithIndividualValues(inputFields, destination);
  });
  // Set up paste asset balances form
  $('#paste-asset-balances-modal').on('shown.bs.modal', function() {
    var destination, inputFields;
    inputFields = $('.js-asset-balance-cash-flow-input');
    destination = $(this).find('#pasted_asset_balance_cash_flows');
    return AliceUtilities.populateWithIndividualValues(inputFields, destination);
  });
  // Update edit multiple loan balances form based on pasted input
  $('#js-update-pasted-loan-balances').click(function() {
    var inputFields, pasteModal, pastedInputField;
    pasteModal = $(this).closest('.modal');
    pastedInputField = pasteModal.find('#pasted_loan_balance_amounts');
    inputFields = $('.js-loan-balance-amount-input');
    AliceUtilities.populateWithPastedValues(pastedInputField, inputFields);
    pastedInputField = pasteModal.find('#pasted_loan_balance_spread_rates');
    inputFields = $('.js-loan-balance-spread-rate-input');
    AliceUtilities.populateWithPastedValues(pastedInputField, inputFields);
    pastedInputField = pasteModal.find('#pasted_loan_balance_budgeted_rates');
    inputFields = $('.js-loan-balance-budgeted-rate-input');
    AliceUtilities.populateWithPastedValues(pastedInputField, inputFields);
    return bootstrap.Modal.getOrCreateInstance(this.closest('.modal')).hide();
  });
  // Update edit multiple asset balances form based on pasted input
  $('#js-update-pasted-asset-balances').click(function() {
    var inputFields, pasteModal, pastedInputField;
    pasteModal = $(this).closest('.modal');
    pastedInputField = pasteModal.find('#pasted_asset_balance_cash_flows');
    inputFields = $('.js-asset-balance-cash-flow-input');
    AliceUtilities.populateWithPastedValues(pastedInputField, inputFields);
    return bootstrap.Modal.getOrCreateInstance(this.closest('.modal')).hide();
  });
  // Clear all loan balance amount fields
  $('.js-clear-loan-balance-amounts').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return form.find('.js-loan-balance-amount-input').val('');
  });
  // Clear all loan balance spread rate fields
  $('.js-clear-loan-balance-spread-rates').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return form.find('.js-loan-balance-spread-rate-input').val('');
  });
  // Clear all loan balance budgeted rate fields
  $('.js-clear-loan-balance-budgeted-rates').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return form.find('.js-loan-balance-budgeted-rate-input').val('');
  });
  // Clear all asset balance cash flow fields
  $('.js-clear-asset-balance-cash-flows').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return form.find('.js-asset-balance-cash-flow-input').val('');
  });
  // Fill empty loan balance amount fields
  $('.js-fill-loan-balance-amounts').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return AliceUtilities.fillListDown(form.find('.js-loan-balance-amount-input'));
  });
  // Fill empty loan balance spread rate fields
  $('.js-fill-loan-balance-spread-rates').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return AliceUtilities.fillListDown(form.find('.js-loan-balance-spread-rate-input'));
  });
  // Fill empty loan balance budgeted rate fields
  $('.js-fill-loan-balance-budgeted-rates').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return AliceUtilities.fillListDown(form.find('.js-loan-balance-budgeted-rate-input'));
  });
  // Fill empty asset balance cash flow fields
  return $('.js-fill-asset-balance-cash-flows').click(function(event) {
    var form;
    form = $(event.target).parents('form');
    return AliceUtilities.fillListDown(form.find('.js-asset-balance-cash-flow-input'));
  });
};

document.addEventListener('turbo:load', function() {
  return initializeLoanAndAssetBalances();
});

document.addEventListener('turbo:render', function() {
  if (!event.detail.isPreview) {
    return initializeLoanAndAssetBalances();
  }
});
