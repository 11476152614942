import {
  Controller
} from '@hotwired/stimulus';

import AliceUtilities from '@/custom/utilities';

export default (function() {
  var _Class;

  // Connects to data-controller="workflow-step"
  _Class = class extends Controller {
    toggle() {
      this.formTarget.requestSubmit();
      // TODO: only display the status indicator if successful
      AliceUtilities.displayAjaxStatusIndicator(this.formTarget, '.success');
      return this.dispatch('toggle');
    }

  };

  _Class.targets = ['form'];

  return _Class;

}).call(this);
