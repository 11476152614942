var initializeAddresses;

import select2 from 'select2';

initializeAddresses = function() {
  var countryCode, countryCodeIsNotPopulated, enableStateCodeSelect, formatSubregion;
  select2($);
  countryCode = function() {
    return $('.select2-country-code').val();
  };
  countryCodeIsNotPopulated = function() {
    return countryCode() === '';
  };
  formatSubregion = function(subregion) {
    return subregion.text;
  };
  enableStateCodeSelect = function() {
    var stateCodeSelect;
    stateCodeSelect = $('.select2-state-code');
    return stateCodeSelect.prop('disabled', countryCodeIsNotPopulated());
  };
  $('select.select2-state-code').select2({
    allowClear: true,
    minimumInputLength: 1,
    ajax: {
      dataType: 'json',
      delay: 250,
      data: function(params) {
        var data;
        return data = {
          country_code: countryCode(),
          subregion: params.term
        };
      },
      processResults: function(data) {
        return {
          results: data
        };
      }
    },
    templateResult: formatSubregion,
    templateSelection: formatSubregion
  });
  // initial check when form loads
  enableStateCodeSelect();
  return $('select.select2-country-code').change(function(event) {
    // clear state code select box
    $('select.select2-state-code').val(null).trigger('change');
    return enableStateCodeSelect();
  });
};

document.addEventListener('turbo:load', function() {
  return initializeAddresses();
});

document.addEventListener('turbo:render', function() {
  if (!event.detail.isPreview) {
    return initializeAddresses();
  }
});
