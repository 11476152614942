var application;

import {
  Application
} from '@hotwired/stimulus';

import {
  appsignal
} from '@/custom/appsignal';

import {
  installErrorHandler
} from '@appsignal/stimulus';

application = Application.start();

installErrorHandler(appsignal, application);

// Configure Stimulus development experience
application.debug = false;

window.Stimulus = application;

export {
  application
};
