import {
  Controller
} from '@hotwired/stimulus';

import select2 from 'select2';

// Connects to data-controller="select2"
export default (class extends Controller {
  connect() {
    var options;
    select2($);
    // Set default theme for all select2 inputs
    $.fn.select2.defaults.set('theme', 'bootstrap-5');
    options = this.element.getAttribute('tags') ? {
      allowClear: true,
      width: 'style',
      tags: [],
      tokenSeparators: [',']
    } : {
      allowClear: true,
      width: 'style'
    };
    // Call this generic select2 instantiation before others with more detailed parameters
    $(this.element).select2(options);
    $('select.select2-ajax').select2({
      allowClear: true,
      minimumInputLength: 1,
      ajax: {
        dataType: 'json',
        delay: 250,
        data: function(params) {
          return {
            query: params.term
          };
        },
        processResults: function(data) {
          return {
            results: data
          };
        }
      },
      templateResult: function(selection) {
        return selection.name;
      },
      templateSelection: function(selection) {
        return selection.text || selection.name;
      }
    });
    return document.addEventListener('turbo:before-cache', function() {
      // N.B. Destroy the select2 functionality before Turbo caches the page so that when
      // clicking the "back" button, the select2 functionality isn't initialized more than once
      return $(this.element).select2('destroy');
    });
  }

});
