var getComputedColor, resolveCssColors;

import 'chartkick';

import Highcharts from 'highcharts/es-modules/masters/highcharts.src';

import 'highcharts/es-modules/masters/modules/accessibility.src.js';

import 'highcharts/es-modules/masters/modules/exporting.src.js';

import 'highcharts/es-modules/masters/modules/offline-exporting.src.js';

import 'highcharts/es-modules/masters/modules/current-date-indicator.src.js';

import 'highcharts/es-modules/masters/modules/timeline.src.js';

window.Highcharts = Highcharts;

// Function to compute CSS variable colors
getComputedColor = function(colorValue) {
  var customProperty, ref;
  if (!(colorValue != null ? colorValue.toString().includes('var(') : void 0)) {
    return colorValue;
  }
  customProperty = (ref = colorValue.toString().match(/var\((.*?)\)/)) != null ? ref[1] : void 0;
  if (!customProperty) {
    return colorValue;
  }
  return getComputedStyle(document.documentElement).getPropertyValue(customProperty).trim();
};

resolveCssColors = function() {
  var colorAttributes, svgElements;
  svgElements = this.renderer.box.getElementsByTagName('*');
  colorAttributes = ['color', 'fill', 'stroke'];
  return Array.from(svgElements).forEach(function(element) {
    return colorAttributes.forEach(function(colorAttribute) {
      var attrValue, computedColor;
      attrValue = element.getAttribute(colorAttribute);
      if (attrValue != null ? attrValue.includes('var(') : void 0) {
        computedColor = getComputedColor(attrValue);
        return element.setAttribute(colorAttribute, computedColor);
      }
    });
  });
};

// Highcharts configuration with theme and export options
document.addEventListener('turbo:load', function() {
  Highcharts.theme = {
    colors: [getComputedColor('var(--palette-color-alpha01)'), getComputedColor('var(--palette-color-alpha02)'), getComputedColor('var(--palette-color-alpha03)'), getComputedColor('var(--palette-color-alpha04)'), getComputedColor('var(--palette-color-alpha05)'), getComputedColor('var(--palette-color-alpha06)'), getComputedColor('var(--palette-color-alpha07)'), getComputedColor('var(--palette-color-alpha08)')],
    chart: {
      style: {
        fontFamily: 'Calibri, sans-serif',
        fontSize: '1rem'
      }
    },
    title: {
      margin: 25,
      style: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: 1.2
      }
    },
    subtitle: {
      align: 'center',
      verticalAlign: 'bottom'
    },
    legend: {
      itemStyle: {
        fontSize: '0.85rem'
      }
    },
    lang: {
      decimalPoint: '.',
      thousandsSep: ','
    },
    exporting: {
      chartOptions: {
        chart: {
          events: {
            load: resolveCssColors
          }
        }
      },
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG']
        }
      }
    }
  };
  return Highcharts.setOptions(Highcharts.theme);
});
