(function() {
  Turbo.StreamActions.animated_replace = function() {
    var i, j, len, len1, ref, ref1, results, tableCell, target;
    ref = this.targetElements;
    for (i = 0, len = ref.length; i < len; i++) {
      target = ref[i];
      target.replaceWith(this.templateContent);
    }
    ref1 = this.targetElements;
    results = [];
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      target = ref1[j];
      results.push((function() {
        var k, len2, ref2, results1;
        ref2 = target.querySelectorAll('td');
        results1 = [];
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          tableCell = ref2[k];
          results1.push(tableCell.animate([
            {
              backgroundColor: 'var(--turbo-transition-success)'
            },
            {
              backgroundColor: 'transparent'
            }
          ], {
            duration: 1000, // milliseconds
            easing: 'ease-in-out'
          }));
        }
        return results1;
      })());
    }
    return results;
  };

}).call(this);
