(function() {
  var AliceUtilities;

  AliceUtilities = class AliceUtilities {
    static displayAjaxStatusIndicator(form, status) {
      var statusIndicator;
      statusIndicator = $(form).find(status);
      return this.fadeElementInAndOut(statusIndicator);
    }

    static fadeElementInAndOut(element) {
      // Requires element to have Bootstrap's "fade" class
      $(element).addClass('show');
      return $(element).one('transitionend', function() {
        return $(element).removeClass('show');
      });
    }

    static showLoadingIndicator() {
      window.scrollTo(0, 0);
      return $('.loading-indicator').show();
    }

    // Strip all characters except for numerical digits, dashes and decimal points
    static stripNonDecimalNumbers(string) {
      if (string != null) {
        return string.replace(/[^\d.-]/g, '');
      }
    }

    // Parse a list of values separated by an end of line character
    static parseValuesPerLine(text) {
      if (text != null) {
        return text.split('\n');
      }
    }

    // Fill the empty inputs for the provided group of input fields
    // with the value from the input above it
    static fillListDown(inputFields) {
      var field, i, j, len, results;
      results = [];
      for (i = j = 0, len = inputFields.length; j < len; i = ++j) {
        field = inputFields[i];
        results.push(AliceUtilities.populateOnlyEmpty(field, inputFields[i - 1]));
      }
      return results;
    }

    // Populate a given input with the provided value (if the input is empty)
    static populateOnlyEmpty(destinationField, sourceField) {
      if (destinationField.value === '' && (sourceField != null)) {
        return destinationField.value = sourceField.value;
      }
    }

    // Populate a single destination field with a concatenated list of source field values
    static populateWithIndividualValues(sourceFields, destinationField) {
      var values;
      if (sourceFields.length > 0 && destinationField.length > 0) {
        values = sourceFields.map(function() {
          return $(this).val();
        });
        return destinationField.val(values.get().join('\n'));
      }
    }

    // Populate a list of destination fields by parsing a single source field
    static populateWithPastedValues(pastedInputField, destinationFields) {
      var destinationField, i, j, len, results, values;
      if (pastedInputField.length > 0 && destinationFields.length > 0) {
        values = AliceUtilities.parseValuesPerLine(pastedInputField.val());
        results = [];
        for (i = j = 0, len = destinationFields.length; j < len; i = ++j) {
          destinationField = destinationFields[i];
          results.push(destinationField.value = AliceUtilities.stripNonDecimalNumbers(values[i]));
        }
        return results;
      }
    }

    static scrollSectionIntoView(targetId) {
      var elementPosition, navbarHeight, offsetPosition;
      navbarHeight = document.getElementById('js-navbar').offsetHeight;
      elementPosition = document.getElementById(targetId).getBoundingClientRect().top;
      offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
      return window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }

  };

  module.exports = AliceUtilities;

}).call(this);
