import {
  Controller
} from '@hotwired/stimulus';

export default (function() {
  var _Class, _updateIndicator, _updateTooltipTitle;

  // Connects to data-controller="workflow-status"
  _Class = class extends Controller {
    connect() {
      return _updateIndicator(this.incompleteCountValue, this.incompleteCountTarget, this.indicatorTarget);
    }

    refresh() {
      return _updateIndicator(this.incompleteCountValue, this.incompleteCountTarget, this.indicatorTarget);
    }

  };

  _Class.targets = ['indicator', 'incompleteCount'];

  _Class.values = {
    incompleteCount: Number
  };

  // Private
  _updateIndicator = function(count, incompleteCountTarget, indicator) {
    count = document.querySelectorAll("#workflow input[type='checkbox']:not(:checked)").length;
    incompleteCountTarget.textContent = count;
    _updateTooltipTitle(count, indicator);
    incompleteCountTarget.style.display = count === 0 ? 'none' : 'inline';
    indicator.classList.toggle('complete', count === 0);
    return indicator.classList.toggle('incomplete', count !== 0);
  };

  _updateTooltipTitle = function(count, indicator) {
    var message;
    message = count === 0 ? 'Complete' : 'Incomplete';
    if (indicator.hasAttribute('data-bs-original-title')) {
      return indicator.setAttribute('data-bs-original-title', message);
    } else {
      return indicator.setAttribute('title', message);
    }
  };

  return _Class;

}).call(this);
