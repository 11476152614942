var _class, compareElements, elementsAreSorted, getSortCode,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import {
  Controller
} from '@hotwired/stimulus';

export default _class = (function() {
  var _Class;

  // Connects to data-controller="sort"
  _Class = class extends Controller {
    constructor() {
      super(...arguments);
      this.append = this.append.bind(this);
    }

    itemTargetConnected(element) {
      return this.sortChildren(this.itemTargets);
    }

    itemTargetDisconnected(element) {
      return this.sortChildren(this.itemTargets);
    }

    // Private
    sortChildren() {
      var children;
      children = this.children();
      if (elementsAreSorted(children)) {
        return;
      }
      return children.sort(compareElements).forEach(this.append);
    }

    children() {
      return Array.from(this.element.children);
    }

    append(child) {
      boundMethodCheck(this, _class);
      return this.element.append(child);
    }

  };

  _Class.targets = ['item'];

  return _Class;

}).call(this);

elementsAreSorted = function([left, ...rights]) {
  var i, len, right;
  for (i = 0, len = rights.length; i < len; i++) {
    right = rights[i];
    if (compareElements(left, right) > 0) {
      return false;
    }
    left = right;
  }
  return true;
};

compareElements = function(left, right) {
  return getSortCode(left) - getSortCode(right);
};

getSortCode = function(element) {
  return element.getAttribute('data-sort-code') || 0;
};
