import Bloodhound from 'corejs-typeahead/dist/bloodhound';

import 'corejs-typeahead/dist/typeahead.jquery';

import AliceUtilities from '@/custom/utilities';

document.addEventListener('turbo:load', function() {
  var queryEngineConstructor;
  queryEngineConstructor = function(sourceUrl) {
    var engine;
    engine = new Bloodhound({
      datumTokenizer: function(datum) {
        return Bloodhound.tokenizers.whitespace(datum);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: sourceUrl,
        prepare: function(query, settings) {
          settings.data = {
            query: query
          };
          return settings;
        }
      }
    });
    return engine.ttAdapter();
  };
  $('.js-typeahead').each(function() {
    var sourceUrl;
    sourceUrl = $(this).data('source');
    return $(this).typeahead({
      classNames: {
        wrapper: 'w-100'
      },
      minLength: 2,
      highlight: true
    }, {
      source: queryEngineConstructor(sourceUrl),
      displayKey: 'name',
      limit: 30, // FIXME: see issue #652
      templates: {
        suggestion: function(suggestion) {
          return `<div><i class='${suggestion.model_icon} fa-fw me-2'></i>` + `<span>${suggestion.name}</span></div>`;
        }
      }
    });
  });
  $('.js-typeahead-with-results-list').on('typeahead:select', function(event, suggestion) {
    var inputId, inputName, template, typeaheadWrapper;
    inputId = `${suggestion.model_type}_ids_${suggestion.id}`;
    inputName = `${suggestion.model_type}_ids[]`;
    template = "<div class='form-check text-nowrap'>" + `<input type='checkbox' name='${inputName}' id='${inputId}' value='${suggestion.id}' ` + "class='form-check-input' checked='checked'>" + `<label class='form-check-label' for='${inputId}'>${suggestion.name}</label>` + '</div>';
    typeaheadWrapper = $(event.target).parents('.js-typeahead-wrapper');
    typeaheadWrapper.find('.js-filtered-models').append(template);
    return this.form.submit();
  });
  $('.js-site-search-typeahead').on('typeahead:select', function(event, suggestion) {
    var searchForm;
    searchForm = $(event.target).parents('form');
    searchForm.find('#matched_model').val(suggestion.model_type);
    searchForm.find('#matched_id').val(suggestion.id);
    return this.form.submit();
  });
  // Submit the form when the filter input is changed
  return $('.js-submit-on-change').on('change', function(event) {
    AliceUtilities.showLoadingIndicator();
    return this.form.submit();
  });
});

document.addEventListener('turbo:before-cache', function() {
  // N.B. Remove typeahead functionality and revert the input element back to its original state
  // before Turbo caches the page so that when clicking the "back" button, the typeahead
  // functionality isn't initialized more than once
  return $('.js-typeahead').typeahead('destroy');
});
