import {
  Controller
} from '@hotwired/stimulus';

import AliceUtilities from '@/custom/utilities';

export default (function() {
  var _Class;

  // Connects to data-controller="accordion"
  _Class = class extends Controller {
    connect() {
      return this.contentTarget.addEventListener('shown.bs.collapse', function(event) {
        var urlHash;
        // Scroll the accordion section to the top of the page when expanded
        AliceUtilities.scrollSectionIntoView(`${event.target.id}-heading`);
        // Update the url when the accordion section is opened
        urlHash = `#${event.target.id}`;
        Turbo.navigator.history.push(urlHash);
        return history.pushState(history.state, null, urlHash);
      });
    }

  };

  _Class.targets = ['content'];

  return _Class;

}).call(this);
