import {
  Controller
} from '@hotwired/stimulus';

import Cocooned from '@notus.sh/cocooned';

export default (function() {
  var _Class, smartPopulateCurrentField, smartPopulateFirstServicePeriodStartDate, smartPopulateServicePeriods, toggleConditionallyDisplayedField;

  // Connects to data-controller="cocooned"
  _Class = class extends Controller {
    connect() {
      Cocooned.create(this.element, {
        animate: false
      });
      // Show/hide the conditionally displayed fields as appropriate on page load
      toggleConditionallyDisplayedField();
      $('.js-conditional-display-trigger').trigger('change');
      // Populate first service period's start date from the contract date
      $('#consulting_agreement_contract_date').on('focusout', function(event) {
        var form;
        form = $(event.target).parents('form');
        return smartPopulateFirstServicePeriodStartDate(form);
      });
      // Set up the conditionally-displayed and smart-populated fields after Cocooned adds the dynamic
      // form to the page
      return document.addEventListener('cocooned:after-insert', function(event) {
        var form, insertedItem;
        insertedItem = event.detail.node;
        toggleConditionallyDisplayedField();
        smartPopulateCurrentField(insertedItem);
        smartPopulateServicePeriods(insertedItem);
        form = $(insertedItem).closest('form');
        return smartPopulateFirstServicePeriodStartDate(form);
      });
    }

  };

  // Toggle the conditionally displayed fields if the triggering selection is chosen
  toggleConditionallyDisplayedField = function() {
    return $('.js-conditional-display-trigger').on('change', function(event) {
      var conditionallyDisplayedFieldGroup, matchesCriteria, row, selectedItem, triggeringItem;
      row = $(event.target).closest('.row');
      if ($(event.target).is(':checkbox')) {
        matchesCriteria = $(event.target).is(':checked');
      } else {
        selectedItem = $(event.target).find('option:selected').text();
        triggeringItem = $(event.target).data('triggering-selection');
        matchesCriteria = selectedItem === triggeringItem;
      }
      conditionallyDisplayedFieldGroup = row.find('.js-conditionally-displayed-field');
      conditionallyDisplayedFieldGroup.toggle(matchesCriteria);
      if (!matchesCriteria) {
        conditionallyDisplayedFieldGroup.find('select').val(null).trigger('change'); // clear value
        return conditionallyDisplayedFieldGroup.find('input').val(null); // clear value
      }
    });
  };

  
  // Default to current item if there isn't yet one
  smartPopulateCurrentField = function(insertedItem) {
    var hasAnyCurrentItems, section;
    section = $(insertedItem).closest('.js-current-group');
    // TODO: this won't work properly with Cocooned if an item is removed from the form first
    hasAnyCurrentItems = $(section).find('.js-current:checked').length > 0;
    return $(insertedItem).find('.js-current').prop('checked', !hasAnyCurrentItems);
  };

  // Populate service period's start date from the previous service period's end date
  smartPopulateServicePeriods = function(insertedItem) {
    var form, hasMultipleServicePeriods, newStartDateField, precedingEndDateField, precedingSP, servicePeriods;
    form = $(insertedItem).closest('form');
    hasMultipleServicePeriods = form.find('.js-nested-service-period-fields').length > 1;
    if (hasMultipleServicePeriods) {
      servicePeriods = form.find('.js-nested-service-period-fields');
      precedingSP = servicePeriods[servicePeriods.length - 2];
      precedingEndDateField = $(precedingSP).find('.consulting_agreement_service_periods_end_date input');
      newStartDateField = $(insertedItem).find('.consulting_agreement_service_periods_start_date input');
      if (precedingEndDateField.val() !== '') {
        return newStartDateField.val(precedingEndDateField.val());
      }
    }
  };

  smartPopulateFirstServicePeriodStartDate = function(form) {
    var contractDate, firstSP, startDateField;
    firstSP = form.find('.js-nested-service-period-fields:first');
    startDateField = firstSP.find('.consulting_agreement_service_periods_start_date input');
    contractDate = form.find('#consulting_agreement_contract_date').val();
    if (startDateField.val() === '' && contractDate !== '') {
      return startDateField.val(contractDate);
    }
  };

  return _Class;

}).call(this);
