var initializeNotionals;

import AliceUtilities from '@/custom/utilities';

import * as bootstrap from 'bootstrap';

initializeNotionals = function() {
  var clearNotionalAmounts, clearStrikeRates, copyNotionalAmounts, copyStrikeRates, fillListFromFirstLeg, fillNotionalAmounts, fillStrikeRates, floatingLeg, floatingLegCount, i, index, initializeModal, populateAndReplace, populateFieldsFromModal, ref, results;
  floatingLegCount = function() {
    return $('#form-wrapper').data('floating-leg-count');
  };
  // Set up paste notional form textareas
  initializeModal = function(floatingLeg, modalId) {
    return $(`#paste-notionals-modal-${modalId}`).on('shown.bs.modal', function() {
      var destination, inputFields;
      inputFields = floatingLeg.find('.js-notional-amount-input');
      destination = $(this).find(`#pasted-notional-amounts-${modalId}`);
      AliceUtilities.populateWithIndividualValues(inputFields, destination);
      inputFields = floatingLeg.find('.js-strike-rate-input');
      destination = $(this).find(`#pasted-strike-rates-${modalId}`);
      return AliceUtilities.populateWithIndividualValues(inputFields, destination);
    });
  };
  // Update edit multiple notional form based on pasted input
  populateFieldsFromModal = function(floatingLeg, modalId) {
    return $(`#update-pasted-notionals-${modalId}`).click(function() {
      var amountFields, pasteModal, pastedAmountsInput, pastedStrikeRatesInput, strikeRateFields;
      pasteModal = $(this).closest('.modal');
      pastedAmountsInput = pasteModal.find(`#pasted-notional-amounts-${modalId}`);
      amountFields = floatingLeg.find('.js-notional-amount-input');
      AliceUtilities.populateWithPastedValues(pastedAmountsInput, amountFields);
      strikeRateFields = floatingLeg.find('.js-strike-rate-input');
      pastedStrikeRatesInput = pasteModal.find(`#pasted-strike-rates-${modalId}`);
      AliceUtilities.populateWithPastedValues(pastedStrikeRatesInput, strikeRateFields);
      return bootstrap.Modal.getOrCreateInstance(this.closest('.modal')).hide();
    });
  };
  // Clear all notional amount fields for a given floating leg
  clearNotionalAmounts = function(floatingLeg) {
    return floatingLeg.find('.js-clear-notional-amounts').click(function() {
      return floatingLeg.find('.js-notional-amount-input').val('');
    });
  };
  // Clear all strike rate fields
  clearStrikeRates = function(floatingLeg) {
    return floatingLeg.find('.js-clear-strike-rates').click(function() {
      return floatingLeg.find('.js-strike-rate-input').val('');
    });
  };
  // Fill the inputs with values from the reciprocal input from leg 1
  fillListFromFirstLeg = function(floatingLeg, inputSelector) {
    var firstLegInputs, i, index, nthLegInputs, ref, results;
    firstLegInputs = $('.floating-leg-0').find(inputSelector);
    nthLegInputs = floatingLeg.find(inputSelector);
    results = [];
    for (index = i = 0, ref = firstLegInputs.length - 1; (0 <= ref ? i <= ref : i >= ref); index = 0 <= ref ? ++i : --i) {
      results.push(populateAndReplace(nthLegInputs[index], firstLegInputs[index]));
    }
    return results;
  };
  // Populate a given input with the provided value,
  // replacing the existing value if necessary
  populateAndReplace = function(field, sourceField) {
    if (sourceField != null) {
      return field.value = sourceField.value;
    }
  };
  // Fill empty notional amount fields
  fillNotionalAmounts = function(floatingLeg) {
    return floatingLeg.find('.js-fill-notional-amounts').click(function() {
      return AliceUtilities.fillListDown(floatingLeg.find('.js-notional-amount-input'));
    });
  };
  // Fill empty strike rate fields
  fillStrikeRates = function(floatingLeg) {
    return floatingLeg.find('.js-fill-strike-rates').click(function() {
      return AliceUtilities.fillListDown(floatingLeg.find('.js-strike-rate-input'));
    });
  };
  // Copy and replace notional amounts from leg 1 to leg n
  copyNotionalAmounts = function(floatingLeg) {
    return floatingLeg.find('.js-copy-notional-amounts').click(function() {
      return fillListFromFirstLeg(floatingLeg, '.js-notional-amount-input');
    });
  };
  // Copy and replace strike rates from leg 1 to leg n
  copyStrikeRates = function(floatingLeg) {
    return floatingLeg.find('.js-copy-strike-rates').click(function() {
      return fillListFromFirstLeg(floatingLeg, '.js-strike-rate-input');
    });
  };
// Set up page bindings for each leg
  results = [];
  for (index = i = 0, ref = floatingLegCount() - 1; (0 <= ref ? i <= ref : i >= ref); index = 0 <= ref ? ++i : --i) {
    floatingLeg = $(`.floating-leg-${index}`);
    clearNotionalAmounts(floatingLeg);
    clearStrikeRates(floatingLeg);
    fillNotionalAmounts(floatingLeg);
    fillStrikeRates(floatingLeg);
    initializeModal(floatingLeg, index);
    populateFieldsFromModal(floatingLeg, index);
    if (index > 0) {
      // Exclude first leg for copying
      copyNotionalAmounts(floatingLeg);
    }
    if (index > 0) {
      results.push(copyStrikeRates(floatingLeg));
    } else {
      results.push(void 0);
    }
  }
  return results;
};

document.addEventListener('turbo:load', function() {
  return initializeNotionals();
});

document.addEventListener('turbo:render', function() {
  if (!event.detail.isPreview) {
    return initializeNotionals();
  }
});
