import {
  Controller
} from '@hotwired/stimulus';

import * as bootstrap from 'bootstrap';

// Connects to data-controller="tooltip"
export default (class extends Controller {
  connect() {
    return this.tooltip = bootstrap.Tooltip.getOrCreateInstance(this.element);
  }

  disconnect() {
    var error, ref;
    try {
      // More at:
      // https://github.com/twbs/bootstrap/issues/37474#issuecomment-1658773983
      return (ref = this.tooltip) != null ? ref.dispose() : void 0;
    } catch (error1) {
      error = error1;
    }
  }

});

// tooltip was already disposed
